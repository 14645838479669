import { PipeTransform, Pipe } from "@angular/core";

export const humanizeMbit = (value: number, fraction?: number, options?: string[]): string => {
  const input = Math.abs(value);
  const usableOptions = options || ["bps", "kbps", "Mbps", "Gbps", "Tbps", "Pbps"];
  const i = input === 0 ? 0 : Math.floor(Math.log(input) / Math.log(1000));
  const simplified = ((1 * input) / Math.pow(1000, i)).toFixed(fraction);
  const ret = simplified + " " + usableOptions[i];
  return ret;
};

@Pipe({
  name: "readable_size",
})
export class ReadableSizePipe implements PipeTransform {
  transform(value: number): string {
    const input = Math.abs(value);
    const i = input === 0 ? 0 : Math.floor(Math.log(input) / Math.log(1000));
    const fraction = i > 1 ? 2 : 0;
    const simplified = ((1 * input) / Math.pow(1000, i)).toFixed(fraction);
    const ret = simplified + " " + ["bps", "kbps", "Mbps", "Gbps", "Tbps", "Pbps"][i];
    return ret;
  }
}

@Pipe({
  name: "readable_packets",
})
export class ReadablePacketSizePipe implements PipeTransform {
  transform(value: number): string {
    return humanizeMbit(value, 2, ["pps", "kpps", "Mpps", "Gpps", "Tpps", "Ppps"]);
  }
}

@Pipe({
  name: "readable_volume",
})
export class ReadableVolumePipe implements PipeTransform {
  transform(value: number): string {
    return humanizeMbit(value, 2, ["b", "kb", "MB", "GB", "TB", "PB"]);
  }
}
