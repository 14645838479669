import { PipeTransform, Pipe } from "@angular/core";
import { ReadableSizePipe } from "../../../../helpers/bitspipe/bitspipe";

@Pipe({
  name: "service_speed",
})
export class ServiceSpeedPipe implements PipeTransform {
  private readableSizePipe = new ReadableSizePipe();
  transform(value: number): string {
    return this.readableSizePipe.transform(value * 1000000);
  }
}
